import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const HelpPage = () => (
  <Layout>
    <Seo title="Help" />
  </Layout>
)

export default HelpPage
